import { Component, OnInit, inject } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MessageService } from '@yuno/angular/notifications';

@Component({
	standalone: true,
	imports: [RouterModule],
	selector: 'yuno-root',
	template: `
		<div class="main-container">
			<router-outlet />
		</div>
	`,
	styleUrls: [`app.component.scss`]
})
export class AppComponent implements OnInit {
	private readonly message = inject(MessageService);

	ngOnInit() {
		this.message.setToastConfig({
			position: 'top-center',
			duration: 1500,
			className: 'mt-safe-toast'
		});
	}
}
